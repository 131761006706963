@import "../../common/variables.scss";

.login-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 0px 15px;
  background: radial-gradient(
    97.91% 97.91% at 22.35% 2.09%,
    #1b2b67 0,
    #161a26 100%
  );
  .voosh-logo {
    width: 150px;
    object-fit: contain;
  }
  .login-container {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2% 5%;
    min-width: 400px;
    background-color: #ffffff;
    .login_title_container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 50px;
      .login_title {
        font-size: 30px;
        font-weight: bold;
      }

      .login_subtitle {
        font-size: 20px;
      }
    }
    .login-form-container {
      border-radius: 10px;
      max-width: 400px;
      background-color: #ffffff;
      padding: 0;
      .login-form-main {
        width: 100%;
        height: 100%;

        .form_input {
          font-size: 0.8rem;
          padding: 10px 30px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 50px;
        }
        .password-eye-button {
          position: absolute;
          right: 15px;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          color: #000000;
        }

        .form-label {
          font-weight: 500;
          font-size: 0.8rem;
        }

        .checkbox-container {
          margin: 30px 0;
          .form-check-label {
            font-size: 12px;
            font-weight: 500;
            color: #000000;
          }
        }
        .password-eye-button {
          position: absolute;
          right: 15px;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          color: #000000;
          top: 11px;
        }
        .login_button {
          border-radius: 50px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #fff;
          padding: 10px;
          font-size: 14px;
          font-weight: 500;
          border: none;
          background-color: #2d66f5;
          width: 100%;
          &:hover {
            background-color: darken(#2d66f5, 10%);
          }
          &:active {
            background-color: darken(#2d66f5, 20%);
          }
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .login_error_container {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2% 5%;
    min-width: 400px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .login_error_img {
      object-fit: contain;
      height: 200px;
    }
    .login_error_text {
      width: 20vw;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 39px;
      text-align: center;
      color: #000000;
    }
    .login_error_button {
      background: #2d66f5;
      box-shadow: 0px 4px 25px rgba(45, 102, 245, 0.25);
      border-radius: 50px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      text-align: right;
      color: #ffffff;
      border: none;
      padding: 1vw 7vw;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
