@import "../../common/variables.scss";

.main-container {
  background-color: #ffffff;
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
  .header-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1.1vw 0px 1.1vw;
    .logo-link {
      width: fit-content;
      height: fit-content;
      .voosh-logo {
        width: 7vw;
        object-fit: contain;
      }
    }
    .logout {
      background-color: #fff;
      color: #000000;
      border: 1px solid darken($color-primary-light, 5%);
      @include font-size($font-small);
      font-weight: 600;
      padding: 5px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      &:hover {
        background-color: darken($color-primary-light, 5%);
      }
      &:active {
        background-color: darken($color-primary-light, 10%);
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .form-container {
    padding: 1vh 1.1vw 1vh 1.1vw;
    margin: 2% 0px;
    .title {
      @include font-size($font-medium);
      font-weight: 600;
      color: #000000;
      margin: 2vh 0px;
    }
    .body {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      padding: 1.5vw 2vw;
      .form-label {
        @include font-size($font-medium);
        font-weight: 500;
        color: #000000;
      }
      .form-control {
        color: #000000;
        font-weight: 500;
        @include font-size($font-small);
      }
      .form-check-label {
        @include font-size($font-small);
        font-weight: 500;
        color: #000000;
      }
      .date-picker {
        color-scheme: none;
        width: fit-content;
      }
      .invalid-feedback {
        display: flex;
        @include font-size($font-small);
        font-weight: 500;
      }
      .upload-handler-container {
        gap: 10px;
        align-items: center;
        .submit-report {
          width: fit-content;
          background-color: darken($color-primary-light, 5%);
          color: #000000;
          border-radius: 0.375rem;
          padding: 5px 10px;
          border: none;
          @include font-size($font-small);
          font-weight: 600;
          &:hover {
            background-color: darken($color-primary-light, 10%);
          }
          &:active {
            background-color: darken($color-primary-light, 15%);
          }
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .custom-bar {
          width: 200px;
          height: 10px;
          font-weight: 500;
          @include font-size($font-small);
          padding: 0px;
        }
      }
    }
  }
  .history-container {
    padding: 0px 1.1vw 1.5vh 1.1vw;
    margin: 2% 0px;
    .title {
      @include font-size($font-medium);
      font-weight: 600;
      color: #000000;
      margin: 2vh 0px;
    }
    .body {
      background-color: #fff;
      table {
        thead {
          tr {
            th {
              @include font-size($font-medium);
              font-weight: 600;
              color: #000000;
              padding: 1vw 0.5vw;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            .sr {
              width: 5%;
            }
            .download {
              width: 10%;
            }
          }
        }
        tbody {
          tr {
            td {
              @include font-size($font-medium);
              font-weight: 500;
              color: #000000;
              padding: 1vw 0.5vw;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            .download {
              text-align: center;
              position: relative;
              button {
                color: #000000;
                background-color: transparent;
                border: none;
                position: relative;
              }
              .download-progress {
                color: $color-primary-dark;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 1;
                top: 0px;
                left: 0px;
              }
            }
            .no-data {
              @include font-size($font-medium);
              font-weight: 500;
              color: #000000;
              padding: 1vw 0.5vw;
              text-align: center;
              vertical-align: middle;
              height: 300px;
            }
          }
        }
      }
    }
  }
}
